<template>
  <form @submit.prevent="onSubmit()">
    <div class="view-header"
      v-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
      </div>
      <div class="view-header__footer">
        <h1 class="page-title"
          v-html="pageTitle"></h1>
      </div>
    </div>
    <div class="view-body">
      <Section class="section section--list">
        <Search :key="componentKey"
          v-model="search"
          returnObject
          :searchEndpoint="list.api.endpoint"
          searchPlaceholder="Rechercher un protocole">
          <template v-slot:suggestions="{ item }">
            <router-link :to="{ name: 'protocol', params: { id: item.id } }">
              {{ item.titre }}
            </router-link>
          </template>
        </Search>

        <List :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
          :target="list.target"
          :disableChangesOn="immutablesProtocoles">

          <template v-slot:titre="{ item }">

            <div class="essai-bpe"
              v-if="item.bpe">
              <div>{{ item.titre }}</div>
              <div class="essai-bpe-icon">
                <SvgIcon name="bpe"
                  size="lg" />
              </div>
            </div>
            <div v-else>
              {{ item.titre }}
            </div>
          </template>

          <template v-slot:date_partage="{ item }">
            {{ helperService.displayDate(item.date_partage) }}
            {{ helperService.displayTime(item.date_partage) }}
          </template>

          <template v-slot:entite_parent="{ item }">
            {{ item.entite_parent.nom }}
          </template>

          <template v-slot:annee_recolte="{ item }">
            {{ item.annee_recolte?.valeur }}
          </template>

          <template v-slot:cultures="{ item }">
            <template v-if="item.cultures.length > 0">
              <div class="tags">
                <MiniTag v-for="culture in item.cultures"
                  :key="culture.id"
                  :id="culture.uid"
                  :text="culture.nom" />
              </div>
            </template>
            <template v-else>
              <span></span>
            </template>
          </template>

          <template v-slot:thematique="{ item }">
            {{ item.thematique?.designation }}
          </template>

          <template v-slot:sous_thematiques="{ item }">
            <template v-if="item.sous_thematiques.length > 0">
              <div class="tags">
                <MiniTag v-for="sous_thematique in item.sous_thematiques"
                  :key="sous_thematique.id"
                  :id="sous_thematique.uid"
                  :text="sous_thematique.nom" />
              </div>
            </template>
            <template v-else>
              <span></span>
            </template>
          </template>

          <template v-slot:statut="{ item }">
            {{ item.statut?.designation }}
          </template>

          <template v-slot:actions="{ item }">
            <Btn icon="thumbs-up"
              grow
              hollow
              color="secondary"
              @click="openModal('protocolChoice', item)" />
          </template>
        </List>
      </Section>
    </div>
  </form>
  <!-- Acceptation du protocole -->
  <Modal title="Action sur le protocole"
    :active="modal.protocolChoice"
    :data="modalData"
    @modal-close="modal.protocolChoice = false">
    <template v-slot:modal-body>
      <p>
        Si vous acceptez ce protocole, il apparaîtra dans la liste des vos
        protocoles actifs.
      </p>
      <p>Êtes-vous sûr de vouloir continuer ?</p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler"
        @click="modal.protocolChoice = false" />
      <Btn v-if="helperService.userHasPermission('protocole_decline')"
        accent
        color="accent"
        text="Refuser"
        @click="refuseProtocol(data.id)" />
      <Btn v-if="helperService.userHasPermission('protocole_accept')"
        text="Accepter"
        @click="acceptProtocol(data.id)"
        color="primary" />
    </template>
  </Modal>
  <Modal title="Action sur le protocole"
    :active="modal.acceptProtocol"
    @modal-close="modal.acceptProtocol = false">
    <template v-slot:modal-body>
      <Container>
        <div class="col-loader">
          <Loader active />
        </div>

        <p>
          Veuillez patienter, <br />
          Le protocole est en cours est en cours d’acceptation.
        </p>
      </Container>
    </template>
  </Modal>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import Search from '@/components/list/Search.vue'
import Modal from '@/components/layout/Modal.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'
import Loader from '@/components/layout/Loader.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'ProtocolsView',

  components: {
    Modal,
    Search,
    Pagination,
    List,
    Btn,
    Section,
    MiniTag,
    SvgIcon,
    Loader,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      immutablesProtocoles: null,
      search: {
        attributs: ['titre'],
        filters: {},
      },

      list: {
        target: {
          route: {
            name: 'protocol',
            paramsFromRoute: undefined,
            itemParams: { id: 'id', from: 'fkro' },
          },
        },
        api: {
          endpoint: 'protocole/partage',
          params: {
            sort: 'date_partage.DESC',
            page: null,
            limit: null,
            filters: 'partage_refuse:isf()',
          },
        },

        headers: [
          { label: 'Titre', dataField: 'titre', isLink: true },
          { label: 'Code national', dataField: 'code_national' },
          { label: 'Code régional', dataField: 'code_regional' },
          { label: 'Date partage', dataField: 'date_partage' },
          { label: 'Entite', hidden: 'tablet' },
          { label: 'Année' },
          { label: 'Cultures', hidden: 'tablet' },
          { label: 'Thématique', hidden: 'tablet' },
          { label: 'Sous-thématiques', hidden: 'tablet' },
        ],
        col: [
          'titre',
          'code_national',
          'code_regional',
          'date_partage',
          'entite_parent',
          'annee_recolte',
          'cultures',
          'thematique',
          'sous_thematiques',
        ],
        key: 'id',
      },

      modalData: {},
      modal: {
        deleteItems: false,
        protocolChoice: false,
        acceptProtocol: false,
      },
      componentKey: 0,
    }
  },

  // If the user reload the page
  mounted() {
    this.fetchService.get('protocole').then((res) => {
      if (res.data.length > 0) {
        const protocoles = res.data
        this.immutablesProtocoles = protocoles
          .filter((p) => p.statut?.uid === 'ENCOURS')
          .map((p) => p.id)
      }
    })
  },

  methods: {

    openModal(modal, data) {
      if (Array.isArray(data)) {
        this.modalData = data.filter((item) => item !== null)
      } else {
        this.modalData = data
      }

      this.modal[modal] = true
    },
    acceptProtocol(id) {
      this.modal.protocolChoice = false
      this.modal.acceptProtocol = true
      this.fetchService.patch(`protocole/${id}/accepter`).then(() => {
        this.emitter.emit('list-refresh')
        this.emitter.emit('alert', {
          type: 'success',
          content:
            'Le protocole a bien été accepté et apparait désormais dans la liste de vos protocoles actifs.',
        })
        this.modal.acceptProtocol = false
      })
    },

    refuseProtocol(id) {
      this.emitter.emit('open-loader')
      this.fetchService.patch(`protocole/${id}/refuser`).then(() => {
        this.emitter.emit('list-refresh')
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Le protocole a bien été refusé.',
        })

        this.emitter.emit('close-loader')
      })
      this.modal.protocolChoice = false
    },
  },
}
</script>

<style scoped>
.col-loader {
  float: left;
  position: relative;
  height: 40px;
  width: 40px;
  margin-right: 40px
}
</style>
